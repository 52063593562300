<template>
  <aside
    class="side-navigation animation"
    :class="{'is-sticky': isSticky, 'is-stopped': isStopped, 'is-scrolled': pageScrolledDown, 'is-opened': isOpened}"
    @click.prevent="toggleNavigation"
    v-observe-visibility="{ callback: animation, once: true}"
    v-click-outside="closeNavigation"
  >
    <nav>
      <a
        class="link"
        :class="{'is-active': currentActive === `service-${linkId}`}"
        v-for="(link, linkId) in navigationList"
        :href="`#service-${linkId}`"
        @click.prevent="scroll(`service-${linkId}`)"
        :key="`service-${linkId}`"
        :ref="`link-service-${linkId}`"
      >{{ link.ShortName }}</a>
    </nav>
  </aside>
</template>

<script>
import navigationSitcking from '../tools/navigationSticking';
import scrollObserver from '../tools/scrollObserver';
import smoothScrollToAnchor from '../tools/smoothScrollToAnchor';
import { animationMixin } from '@/components/mixins/animation';

export default {
  name: 'SideNavigation',
  mixins: [animationMixin],
  data() {
    return {
      isSticky: false,
      isStopped: false,
      pageOnTop: true,
      pageScrolledDown: false,
      isOpened: false,
    };
  },
  computed: {
    breakpoint() {
      return this.$store.getters.getBreakpoint;
    },
  },
  props: {
    currentActive: {
      type: String,
      default: undefined,
    },
    navigationList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    scroll(id) {
      const target = document.getElementById(id);
      smoothScrollToAnchor(target);
    },
    toggleNavigation() {
      if (this.breakpoint === 'sm') {
        this.isOpened = !this.isOpened;
      }
    },
    closeNavigation() {
      if (this.breakpoint === 'sm') {
        this.isOpened = false;
      }
    },
  },
  mounted() {
    navigationSitcking(this);
    scrollObserver(this);
  },
  watch: {
    currentActive(currId) {
      const el = this.$refs[`link-${currId}`];
      if (el) {
        // eslint-disable-next-line no-unused-expressions
        el?.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-navigation {
  $b: &;
  position: fixed;
  left: 0;
  top: 0;
  color: color(basic-light);
  z-index: 10;
  // max-height: calc(100vh - 100px);
  overflow: auto;
  transition: padding 0.3s ease;

  @include native-scroll(color(border), color(dark), 6, 6);

  @include wRule(
    max-height,
    (
      xl: 612,
      lg: 456,
      md: 456,
    )
  );

  @include wRule(
    margin-top,
    (
      xl: 276,
      lg: 212,
      md: 167,
      sm: 0,
    )
  );

  @include screen("sm", max) {
    position: fixed;
    top: pxtovw(93, sm);
    transition: top $ease-main;
    height: pxtovw(125, sm);
    max-height: calc(100% - (9300 / 640) * 1vw);
    overflow: hidden;
  }

  @include screen("xxl", min) {
    padding-top: 276px;
  }

  &.is-animated {
    animation: slideLeft 0.5s ease;
  }

  &.is-sticky {
    @include screen("sm", min) {
      position: fixed;
      // top: 100px;
    }

    @include wRule(
      margin-top,
      (
        xl: 100,
        lg: 100,
        md: 100,
        sm: 0,
      )
    );

    @include screen("xxl", min) {
      margin-top: 276px;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: color(dark);
  }

  .link {
    width: 100%;
    display: flex;
    align-items: center;
    transition: backround-color $ease-main, opacity $ease-main;
    box-sizing: border-box;
    flex: none;

    @include screen("sm", max) {
      pointer-events: none;
      opacity: 0;
      order: 2;
    }

    @include wRule(
      font-size,
      (
        xl: 20,
        lg: 16,
        md: 16,
        sm: 30,
      )
    );

    @include wRule(
      padding,
      (
        xl: 5 58 5 104,
        lg: 5 20 5 75,
        md: 5 20 5 55,
        sm: 5 45,
      )
    );

    @include wRule(
      min-height,
      (
        xl: 102,
        lg: 76,
        md: 76,
        sm: 125,
      )
    );

    &.is-active {
      background-color: color(hover);

      @include screen("sm", max) {
        opacity: 1;
        order: 1;
      }
    }

    &:hover {
      @media (hover: hover) {
        background-color: color(hover);
      }
    }
  }

  &.is-stopped {
    @include screen("sm", min) {
      position: absolute;
      top: auto;
      bottom: 0;
    }

    @include wRule(
      margin-top,
      (
        xl: 100,
        lg: 100,
        md: 100,
        sm: 0,
      )
    );

    @include screen(xl, min) {
      margin-top: 276px;
    }
  }

  &.is-scrolled {
    @include screen("sm", max) {
      top: 0;
      max-height: 100%;
    }
  }

  &.is-opened {
    @include screen("sm", max) {
      height: auto;
      overflow: auto;
      z-index: 30;
    }

    .link {
      @include screen("sm", max) {
        pointer-events: auto;
        opacity: 1;
        order: 0;
      }
    }
  }

  @include wRule(
    width,
    (
      xl: 342,
      lg: 253,
      md: 237,
      sm: 640,
    )
  );
}
</style>
